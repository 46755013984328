import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


const EmptyModal = ({ showModal, toggleModal }) => {

    return (
        <>
            <Modal
              size="lg"
              isOpen={showModal}
              toggle={toggleModal}
            >
              <ModalHeader toggle={toggleModal}></ModalHeader>
              <ModalBody>
                <div>
                  There are no pictures currently available. Check back
                  soon!
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={toggleModal}>
                  Go Back
                </Button>
              </ModalFooter>
            </Modal>
        </>
    );
}
  
export default EmptyModal;