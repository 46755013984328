import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Testimonial from "./Testimonial.js";
import './FooterTestimonials.css'

const FooterTestimonials = () => {

  return (
    <Carousel
      showArrows={false}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={4500}
    >
      <Testimonial
        testimonialName='Elizabeth Merrigan'
        testimonialService='Customer since 2018'
        testimonialText='My entire condo owes its beauty and functionality to Chris...there was no one
        else that I considered for my extensive remodeling.'
      />
      <Testimonial
        testimonialName='Sherry Johnston'
        testimonialService='Customer since 2013'
        testimonialText='I’ve worked with Chris for the last 10 years to remodel two homes. He is honest,
        creative, hard working, dependable and really a joy to work with.'
      />
      <Testimonial
        testimonialName='Aaron Fasting'
        testimonialService='Customer since 2006'
        testimonialText='There is no one more genuine and honest in the business than Chris. He always goes the extra
        mile to do the right thing and his eye for design is exceptional.'
      />
      <Testimonial
        testimonialName='Jeanne P'
        testimonialService='Customer since 2021'
        testimonialText='We have found Chris to be knowledgeable, honest, reliable, responsive and able to be creative when unanticipated issues arise.'
      />
      <Testimonial
        testimonialName='Lauren Foley'
        testimonialService='Customer since 2022'
        testimonialText='From the initial consultation to the final touches, Chris demonstrated professionalism and dedication...
        his expertise and skill were evident in every step of the process.'
      />
      <Testimonial
        testimonialName='Jacob W'
        testimonialService='Customer since 2023'
        testimonialText='Chris stepped in to take over a basement remodel project and he provided great consultation and advice -
        his proactive communication and coordination was excellent, we were thrilled with the results!'
      />
    </Carousel>
  );
}

export default FooterTestimonials