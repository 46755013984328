import React from 'react';
import './FooterBBB.css';


const FooterBBB = () => {

    return (
        <div className="logo-bbb">
            <a href="https://www.bbb.org/us/wi/menomonee-falls/profile/general-contractor/cml-residential-remodeling-llc-0694-1000055016/#sealclick"
                target="_blank" rel="noreferrer">
                <img src="https://seal-wisconsin.bbb.org/seals/blue-seal-293-61-bbb-1000055016.png" alt="CML Residential Remodeling, LLC BBB Business Review" />
            </a>
        </div>
    )
}

export default FooterBBB;
