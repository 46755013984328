import React, {useState} from "react";
import '../../PortfolioProjectsNav/PortfolioProjectsNav.css';
import TransformationsExample from "../../../assets/images/Transformations/Transformations-Example.jpg";
import ImagesGallery from "../../ImagesGallery/ImagesGallery";
import imagesList from '../../../assets/images/imagesIndex';

const imageImport = [
  imagesList.transformationImages
]

const Transformations = () => {

  const [showModal, setShowModal] = useState(false)

  const modalTitle = "Transformations Portfolio"

  const openModal = () => {
    setShowModal(prevState => !prevState)
  }

  return (
    <div className="cml-portfolio-projects-nav">
      <h1>Transformations</h1>
      <div className="content" onClick={openModal}>
        <img className="content-image" src={TransformationsExample} alt="Transformations" />
        <div className="content-overlay"></div>
        <div className="content-details fadeIn-top">
          <h3>Transformations</h3>
        </div>
      </div>
      <ImagesGallery showModal={showModal} setShowModal={setShowModal} imagesImport={imageImport[0]} modalTitle={modalTitle}/>
    </div>
  );
};

export default Transformations;