import React from 'react';
import './Header.css';
import logoUrl from '../../assets/images/logo-1.png';

const Header = () => {

    return (
        <div className="header">
            <img src={logoUrl} alt="CML" />
        </div>
    )
}

export default Header;