import React from "react";
import "./PortfolioNav.css";
import Portfolio from "../../assets/images/Portfolio-Example.jpg";
import { Link } from "react-scroll";

const PortfolioNav = () => {
  return (
    <div className="cml-portfolio-nav">
      <h1>Work Portfolio</h1>
      <div className="content">
        <Link activeClass="active" to="portfolio-scroll" offset={-20} smooth={false} duration={500}>
          <img className="content-image" src={Portfolio} alt="Portfolio" />
          <div className="content-overlay"></div>
          <div className="content-details fadeIn-top">
            <p><b>Check out my work!</b></p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PortfolioNav;
