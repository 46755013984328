import React, { useState, useRef, useCallback } from "react";
import "./ContactForm.css";
import { Container } from "reactstrap";

const ContactForm = () => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    reason: "",
    message: "",
  });

  const handleInputChange = useCallback((event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const selectOptionElement = useRef(null);

  const [submitted, setSubmitted] = useState(false);

  const [valid, setValid] = useState(false);

  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const successFormReset = () => {
    setIsMessageVisible(true);
    setTimeout(() => {
      setIsMessageVisible(false);
      setValues(() => ({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        reason: (selectOptionElement.current.value = ""),
        message: "",
      }));
      setSubmitted(false);
      setValid(false);
      setSubmitDisabled(false);
    }, 4000);
  };

  const errorFormReset = () => {
    setIsErrorMessageVisible(true);
    setTimeout(() => {
      setIsErrorMessageVisible(false);
      setValues(() => ({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        reason: (selectOptionElement.current.value = ""),
        message: "",
      }));
      setSubmitted(false);
      setValid(false);
      setSubmitDisabled(false);
    }, 4000);
  };

  let formValid = false;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      values.firstName &&
      values.lastName &&
      values.phone &&
      values.email &&
      values.reason
    ) {
      setValid(true);
      formValid = true;
      setSubmitDisabled(true);
    }
    setSubmitted(true);

    if (formValid) {
      let templateParams = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        reason: values.reason,
        message: values.message,
      };

      fetch(process.env.REACT_APP_BACKEND_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(templateParams),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to send email");
          }
          return res.json();
        })
        .then((data) => {
          successFormReset();
        })
        .catch((err) => {
          errorFormReset();
          console.log("error: ", err);
        });
    }
  };

  return (
    <div className="form-header">
      <Container>
        <div className="contact-scroll">
          <h1>Contact Me!</h1>
        </div>
        <div className="form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            {isMessageVisible ? (
              <div className="success-message">
                Your message has been sent successfully! We will be in contact
                soon!
              </div>
            ) : null}
            {isErrorMessageVisible ? (
              <div className="error-message">
                Oops! Something went wrong! Please try again soon!
              </div>
            ) : null}
            <input
              id="first-name"
              className="form-field"
              type="text"
              placeholder="First Name"
              name="firstName"
              value={values.firstName}
              onChange={handleInputChange}
            />
            {submitted && !valid && !values.firstName ? (
              <span>Please enter a first name</span>
            ) : null}
            <input
              id="last-name"
              className="form-field"
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleInputChange}
            />
            {submitted && !valid && !values.lastName ? (
              <span>Please enter a last name</span>
            ) : null}
            <input
              id="phone"
              className="form-field"
              type="text"
              placeholder="Phone"
              name="phone"
              value={values.phone}
              onChange={handleInputChange}
            />
            {submitted && !valid && !values.phone ? (
              <span>Please enter a valid phone number</span>
            ) : null}
            <input
              id="email"
              className="form-field"
              type="text"
              placeholder="Email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
            />
            {submitted && !valid && !values.email ? (
              <span>Please enter a valid email address</span>
            ) : null}
            <select
              ref={selectOptionElement}
              id="reason"
              className="form-field"
              placeholder="Reason"
              name="reason"
              onChange={handleInputChange}
              defaultValue=""
            >
              <option disabled value="">
                -- Reason for Contact --
              </option>
              <option>I would like an estimate for a new project</option>
              <option>I have a question about your services</option>
              <option>I am interesting in working for CML Remodeling</option>
              <option>I would like to write a review for your website</option>
              <option>
                I have a general comment or something else to provide
              </option>
            </select>
            {submitted && !valid && !values.reason ? (
              <span>Please enter a reason for contact</span>
            ) : null}
            <textarea
              id="message"
              className="form-field"
              rows="8"
              type="text"
              placeholder="Message"
              name="message"
              value={values.message}
              onChange={handleInputChange}
            />
            <button
              className="form-field"
              type="submit"
              disabled={submitDisabled}
            >
              Submit
            </button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
