import React from 'react';
import '../Portfolio/Portfolio.css';
import { Container, Row, Col } from 'reactstrap';
import Kitchens from '../../components/PortfolioProjectsNav/Kitchens/Kitchens';
import Bathrooms from '../../components/PortfolioProjectsNav/Bathrooms/Bathrooms';
import Transformations from '../../components/PortfolioProjectsNav/Transformations/Transformations';

const Portfolio = () => {
    return (
        <div className="cml-portfolio">
            <Container>
                <div className="portfolio-scroll">
                    <h1>Work Portfolio</h1>
                </div>
                <Row className="mt-5">
                    <Col><Kitchens /></Col>
                    <Col><Bathrooms /></Col>
                </Row>
                <Row className="mt-5">
                    <Col><Transformations /></Col>
                </Row>
            </Container>
        </div>
    )
}
export default Portfolio;