import React from "react";
import "./ContactNav.css";
import Contact from "../../assets/images/Contact-Example.jpg";
import { Link } from "react-scroll";

const ContactNav = () => {
  return (
    <div className="cml-contact-nav">
      <h1>Contact CML</h1>
      <div className="content">
        <Link activeClass="active" to="contact-scroll" offset={-20} smooth={false} duration={600}>
          <img className="content-image" src={Contact} alt="Contact" />
          <div className="content-overlay"></div>
          <div className="content-details fadeIn-top">
            <p><b>Send me a message!</b></p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ContactNav;
