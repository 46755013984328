import React from "react";
import "./AboutNav.css";
import About from "../../assets/images/About-Example.jpg";
import { Link } from "react-scroll";

const AboutNav = () => {
  return (
    <div className="cml-about-nav">
      <h1>About CML</h1>
      <div className="content">
        <Link activeClass="active" to="about-scroll" offset={-20} smooth={false} duration={500}>
          <img className="content-image" src={About} alt="About" />
          <div className="content-overlay"></div>
          <div className="content-details fadeIn-top">
            <p><b>Learn about my background!</b></p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AboutNav;
