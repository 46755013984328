import React from 'react';
import './Footer.css';
import FooterBBB from './FooterBBB';
import FooterTestimonials from './FooterTestimonials'
import FooterMapping from './FooterMapping'
import SocialMedia from './SocialMedia';

const Footer = () => (
  <footer className="footer">
    <div className="footer-left col-md-4 col-sm-6">
      <FooterBBB />
      <SocialMedia />
    </div>
    <div className="footer-center col-md-4 col-sm-6">
      <FooterTestimonials />
    </div>
    <div className="footer-right col-md-4 col-sm-6">
      <FooterMapping />
    </div>
  </footer>
)

export default Footer;