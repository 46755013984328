import './App.css';
import About from './components/About/About';
import ContactForm from './components/ContactForm/ContactForm';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CmlNav from './containers/CmlNav/CmlNav';
import Portfolio from './containers/Portfolio/Portfolio';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });
  
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Header />
        <CmlNav />
        <About />
        <Portfolio />
        <ContactForm />
        <Footer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
