import React, {useState} from "react";
import '../../PortfolioProjectsNav/PortfolioProjectsNav.css';
import BathroomsExample from "../../../assets/images/Bathrooms/Bathrooms-Example.jpg";
import ImagesGallery from "../../ImagesGallery/ImagesGallery";
import imagesList from '../../../assets/images/imagesIndex';

const imageImport = [
  imagesList.bathroomImages
]

const Bathrooms = () => {

  const [showModal, setShowModal] = useState(false)

  const modalTitle = "Bathrooms Portfolio"

  const openModal = () => {
    setShowModal(prevState => !prevState)
  }

  return (
    <div className="cml-portfolio-projects-nav">
      <h1>Bathrooms</h1>
      <div className="content" onClick={openModal}>
        <img className="content-image" src={BathroomsExample} alt="Bathrooms" />
        <div className="content-overlay"></div>
        <div className="content-details fadeIn-top">
          <h3>Bathrooms</h3>
        </div>
      </div>
      <ImagesGallery showModal={showModal} setShowModal={setShowModal} imagesImport={imageImport[0]} modalTitle={modalTitle}/>
    </div>
  );
};

export default Bathrooms;
