import React from "react";
import { Container } from "reactstrap";
import "./About.css";

const About = () => (
  <div className="cml-about">
    <Container>
      <div className="about-scroll">
        <h1>About CML Residential</h1>
      </div>
      <p>
        Hi, I'm Chris, owner of CML residential remodeling. I have been working in the industry for over 20 years.
        While tackling the unique challenges of each job, I became excited about the projects where I frequently
        noticed a lot of issues and wanted to solve what I saw and make it right.
        Once I realized my passion for resolving issues and improving on them, I knew that I wanted to better my
        knowledge and skills. I attended and graduated with honors from MATC and Concordia University with degrees
        in Design & Marketing. My on-the-job experience combined with my university education opened the door to
        exploring more complex and in-depth remodeling projects which ultimately led me to start my own business.
      </p>
      <p>
        As I progressed and grew my craft, I regularly noticed design flaws and builder errors that cause common household problems
        that could have been avoided with proper thought and attention. This empowered me to do better and fix the root issues that
        are considered “behind the scenes”. I do this by placing emphasis on the actual structure and paying attention
        to detail - for example, common household problems are frequently caused by unseen water damage and spending the time to provide
        extra water proofing measures goes a long way to preventing future deceptive problems.
      </p>
      <p>
        I was always taught to do the right thing because it is the right thing to do. That attitude has stayed with me on each job-site
        and with each customer I work with. The greatest reward on every project is helping the homeowner achieve their dream design!
        This is a transformational process from start to finish. The end result of all projects is that it not only aesthetically pops but is
        also satisfyingly functional - both outside and inside the walls.
      </p>
    </Container>
  </div>
);
export default About;
