import React from 'react';
import '../CmlNav/CmlNav.css';
import { Container, Row, Col } from 'reactstrap';
import AboutNav from '../../components/AboutNav/AboutNav';
import ContactNav from '../../components/ContactNav/ContactNav';
import PortfolioNav from '../../components/PortfolioNav/PortfolioNav';

function CmlNav() {
  return (
    <div className="cml-nav">
      <Container>
          <Row>
            <Col><AboutNav /></Col>
            <Col><PortfolioNav /></Col>
            <Col><ContactNav /></Col>
          </Row>
      </Container>
    </div>
  );
}

export default CmlNav;