import React from "react";


const Testimonial = ({ testimonialName, testimonialService, testimonialText }) => {

    return (

        <div className="footer-carousel">
            <h3>{testimonialName}</h3>
            <h4>{testimonialService}</h4>
            <p>{testimonialText}</p>
        </div>
    );
}

export default Testimonial