import React from 'react';
import logoUrl from '../../assets/images/logo-1.png';
import { Link } from 'react-scroll';
import './FooterMapping.css'

const FooterMapping = () => {

    return (
        <div className="cml-mapping">
            <img src={logoUrl} alt="CML" />
            <p className="menu">
                <a href="/#"><Link activeClass="active" to="header" offset={-20} smooth={false} duration={600}> Home</Link></a> |
                <a href="/#"><Link activeClass="active" to="about-scroll" offset={-20} smooth={false} duration={600}> About</Link></a> |
                <a href="/#"><Link activeClass="active" to="portfolio-scroll" offset={-20} smooth={false} duration={500}> Portfolio</Link></a> |
                <a href="/#"><Link activeClass="active" to="contact-scroll" offset={-20} smooth={false} duration={500}> Contact</Link></a>
            </p>
            <p className="name"> CML Residential Remodeling LLC &copy; {new Date().getFullYear()}</p>
        </div>
    )
}

export default FooterMapping;