import ImageGallery from "react-image-gallery";
import { Modal, ModalHeader, ModalBody } from "reactstrap";


const ImagesModal = ({ showModal, toggleModal, images, modalTitle }) => {

    return (
        <>
            <Modal
                size="lg"
                isOpen={showModal}
                toggle={toggleModal}
            >
                <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <ImageGallery items={images} />
                </ModalBody>
            </Modal>
        </>
    );
}
  
export default ImagesModal;