import React, { useState, useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "../ImagesGallery/ImagesGallery.css";
import ImagesModal from "../Modals/ImagesModal.js"
import EmptyModal from "../Modals/EmptyModal.js";


const ImagesGallery = ({ showModal, setShowModal, imagesImport, modalTitle }) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    let shouldCancel = false;

    if (!shouldCancel && imagesImport.length > 0) {
      setImages(
        imagesImport.map((imagesResult) => ({
          original: `${imagesResult.imagePath}`,
          thumbnail: `${imagesResult.imagePath}`,
          description: `${imagesResult.imageName}`
        }))
      )
    }

    return () => (shouldCancel = true);
  }, [imagesImport]);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  return (
    <>
      {showModal ? (
        images ? (
          <>
            <ImagesModal showModal={showModal} toggleModal={toggleModal} images={images} modalTitle={modalTitle}></ImagesModal>
          </>
        ) : (
          <>
            <EmptyModal showModal={showModal} toggleModal={toggleModal}></EmptyModal>
          </>
        )
      ) : null}
    </>
  );
};

export default ImagesGallery;