import React from "react";
import "./SocialMedia.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const SocialMedia = () => {
  return (
    <div className="singleCol">
      <a href="https://www.facebook.com/" className="facebook social">
        <FontAwesomeIcon
          icon={icon({ name: "facebook", style: "brands" })}
          size="2xl"
        />
      </a>

      <a href="https://www.google.com/" className="google social">
        <FontAwesomeIcon
          icon={icon({ name: "google", style: "brands" })}
          size="2xl"
        />
      </a>

      <a href="https://twitter.com/" className="twitter social">
        <FontAwesomeIcon
          icon={icon({ name: "twitter", style: "brands" })}
          size="2xl"
        />
      </a>

      <a href="https://www.instagram.com/" className="instagram social">
        <FontAwesomeIcon
          icon={icon({ name: "instagram", style: "brands" })}
          size="2xl"
        />
      </a>

      <a href="https://www.youtube.com/" className="youtube social">
        <FontAwesomeIcon
          icon={icon({ name: "youtube", style: "brands" })}
          size="2xl"
        />
      </a>
    </div>
  );
};

export default SocialMedia;
