import React, {useState} from "react";
import '../../PortfolioProjectsNav/PortfolioProjectsNav.css';
import KitchensExample from "../../../assets/images/Kitchens/Kitchens-Example.jpg";
import ImagesGallery from "../../ImagesGallery/ImagesGallery";
import imagesList from '../../../assets/images/imagesIndex';

const imageImport = [
  imagesList.kitchenImages
]

const Kitchens = () => {

  const [showModal, setShowModal] = useState(false)

  const modalTitle = "Kitchens Portfolio"

  const openModal = () => {
    setShowModal(prevState => !prevState)
  }

  return (
    <div className="cml-portfolio-projects-nav">
      <h1>Kitchens</h1>
      <div className="content" onClick={openModal}>
        <img className="content-image" src={KitchensExample} alt="Kitchens" />
        <div className="content-overlay"></div> 
        <div className="content-details fadeIn-top">
          <h3>Kitchens</h3>
        </div>
      </div>
      <ImagesGallery showModal={showModal} setShowModal={setShowModal} imagesImport={imageImport[0]} modalTitle={modalTitle}/>
    </div>
  );
};

export default Kitchens;
